import { default as aboutv3Wuob7z2aMeta } from "/app/src/pages/about.vue?macro=true";
import { default as _91name_930g3eqI3J0IMeta } from "/app/src/pages/cases/[name].vue?macro=true";
import { default as indexUmKUqpXlhzMeta } from "/app/src/pages/cases/index.vue?macro=true";
import { default as casesj3TaapTKs0Meta } from "/app/src/pages/cases.vue?macro=true";
import { default as developers0AZyZ7wzWyMeta } from "/app/src/pages/developers.vue?macro=true";
import { default as hk2024_45bannerIlRveaRpMdMeta } from "/app/src/pages/events/hk2024-banner.vue?macro=true";
import { default as hk2024qZH23YObV2Meta } from "/app/src/pages/events/hk2024.vue?macro=true";
import { default as token2049_45bannerk3Lw9bvdfCMeta } from "/app/src/pages/events/token2049-banner.vue?macro=true";
import { default as token2049kRfiaMobqVMeta } from "/app/src/pages/events/token2049.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as kycysXA7IzKSKMeta } from "/app/src/pages/kyc.vue?macro=true";
import { default as legend_45otcavja6wauzeMeta } from "/app/src/pages/legend-otc.vue?macro=true";
import { default as legend_45payqtDI5tR7Z0Meta } from "/app/src/pages/legend-pay.vue?macro=true";
import { default as licenses5EIU76vKO4Meta } from "/app/src/pages/licenses.vue?macro=true";
import { default as media_45kitPKsYg3on5cMeta } from "/app/src/pages/media-kit.vue?macro=true";
import { default as newsroom_46unusez4lGnNfAPMMeta } from "/app/src/pages/newsroom.unuse.vue?macro=true";
import { default as _91_46_46_46slug_93pbKmIwuImnMeta } from "/app/src/pages/newsroom/[...slug].vue?macro=true";
import { default as _91slug_93_46unuse5ZIbAY40pUMeta } from "/app/src/pages/newsroom/[slug].unuse.vue?macro=true";
import { default as indexOI5y9jxWrtMeta } from "/app/src/pages/newsroom/index.vue?macro=true";
import { default as rss_46unuseKhK8TGYg55Meta } from "/app/src/pages/newsroom/rss.unuse.vue?macro=true";
import { default as newsroom5XLeoaQJfkMeta } from "/app/src/pages/newsroom.vue?macro=true";
import { default as faqh01nKZiDKKMeta } from "/app/src/pages/partner/faq.vue?macro=true";
import { default as indexZZTBKuA4nFMeta } from "/app/src/pages/partner/index.vue?macro=true";
import { default as policyIAFIBEaui8Meta } from "/app/src/pages/policy.vue?macro=true";
import { default as unsubscribeRm0F0pnYwrMeta } from "/app/src/pages/preference/email/unsubscribe.vue?macro=true";
import { default as termsXCu4sSphCUMeta } from "/app/src/pages/terms.vue?macro=true";
export default [
  {
    name: "about___en___default",
    path: "/about",
    meta: aboutv3Wuob7z2aMeta || {},
    component: () => import("/app/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/en/about",
    meta: aboutv3Wuob7z2aMeta || {},
    component: () => import("/app/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___de",
    path: "/de/about",
    meta: aboutv3Wuob7z2aMeta || {},
    component: () => import("/app/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___es_MX",
    path: "/es_MX/about",
    meta: aboutv3Wuob7z2aMeta || {},
    component: () => import("/app/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___pt_BR",
    path: "/pt_BR/about",
    meta: aboutv3Wuob7z2aMeta || {},
    component: () => import("/app/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___zh_Hans",
    path: "/zh_Hans/about",
    meta: aboutv3Wuob7z2aMeta || {},
    component: () => import("/app/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___zh_Hant",
    path: "/zh_Hant/about",
    meta: aboutv3Wuob7z2aMeta || {},
    component: () => import("/app/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: casesj3TaapTKs0Meta?.name,
    path: "/cases",
    meta: casesj3TaapTKs0Meta || {},
    component: () => import("/app/src/pages/cases.vue").then(m => m.default || m),
    children: [
  {
    name: "cases-name___en___default",
    path: ":name()",
    component: () => import("/app/src/pages/cases/[name].vue").then(m => m.default || m)
  },
  {
    name: "cases___en___default",
    path: "",
    component: () => import("/app/src/pages/cases/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: casesj3TaapTKs0Meta?.name,
    path: "/en/cases",
    meta: casesj3TaapTKs0Meta || {},
    component: () => import("/app/src/pages/cases.vue").then(m => m.default || m),
    children: [
  {
    name: "cases-name___en",
    path: ":name()",
    component: () => import("/app/src/pages/cases/[name].vue").then(m => m.default || m)
  },
  {
    name: "cases___en",
    path: "",
    component: () => import("/app/src/pages/cases/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: casesj3TaapTKs0Meta?.name,
    path: "/de/cases",
    meta: casesj3TaapTKs0Meta || {},
    component: () => import("/app/src/pages/cases.vue").then(m => m.default || m),
    children: [
  {
    name: "cases-name___de",
    path: ":name()",
    component: () => import("/app/src/pages/cases/[name].vue").then(m => m.default || m)
  },
  {
    name: "cases___de",
    path: "",
    component: () => import("/app/src/pages/cases/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: casesj3TaapTKs0Meta?.name,
    path: "/es_MX/cases",
    meta: casesj3TaapTKs0Meta || {},
    component: () => import("/app/src/pages/cases.vue").then(m => m.default || m),
    children: [
  {
    name: "cases-name___es_MX",
    path: ":name()",
    component: () => import("/app/src/pages/cases/[name].vue").then(m => m.default || m)
  },
  {
    name: "cases___es_MX",
    path: "",
    component: () => import("/app/src/pages/cases/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: casesj3TaapTKs0Meta?.name,
    path: "/pt_BR/cases",
    meta: casesj3TaapTKs0Meta || {},
    component: () => import("/app/src/pages/cases.vue").then(m => m.default || m),
    children: [
  {
    name: "cases-name___pt_BR",
    path: ":name()",
    component: () => import("/app/src/pages/cases/[name].vue").then(m => m.default || m)
  },
  {
    name: "cases___pt_BR",
    path: "",
    component: () => import("/app/src/pages/cases/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: casesj3TaapTKs0Meta?.name,
    path: "/zh_Hans/cases",
    meta: casesj3TaapTKs0Meta || {},
    component: () => import("/app/src/pages/cases.vue").then(m => m.default || m),
    children: [
  {
    name: "cases-name___zh_Hans",
    path: ":name()",
    component: () => import("/app/src/pages/cases/[name].vue").then(m => m.default || m)
  },
  {
    name: "cases___zh_Hans",
    path: "",
    component: () => import("/app/src/pages/cases/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: casesj3TaapTKs0Meta?.name,
    path: "/zh_Hant/cases",
    meta: casesj3TaapTKs0Meta || {},
    component: () => import("/app/src/pages/cases.vue").then(m => m.default || m),
    children: [
  {
    name: "cases-name___zh_Hant",
    path: ":name()",
    component: () => import("/app/src/pages/cases/[name].vue").then(m => m.default || m)
  },
  {
    name: "cases___zh_Hant",
    path: "",
    component: () => import("/app/src/pages/cases/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "developers___en___default",
    path: "/developers",
    meta: developers0AZyZ7wzWyMeta || {},
    component: () => import("/app/src/pages/developers.vue").then(m => m.default || m)
  },
  {
    name: "developers___en",
    path: "/en/developers",
    meta: developers0AZyZ7wzWyMeta || {},
    component: () => import("/app/src/pages/developers.vue").then(m => m.default || m)
  },
  {
    name: "developers___de",
    path: "/de/developers",
    meta: developers0AZyZ7wzWyMeta || {},
    component: () => import("/app/src/pages/developers.vue").then(m => m.default || m)
  },
  {
    name: "developers___es_MX",
    path: "/es_MX/developers",
    meta: developers0AZyZ7wzWyMeta || {},
    component: () => import("/app/src/pages/developers.vue").then(m => m.default || m)
  },
  {
    name: "developers___pt_BR",
    path: "/pt_BR/developers",
    meta: developers0AZyZ7wzWyMeta || {},
    component: () => import("/app/src/pages/developers.vue").then(m => m.default || m)
  },
  {
    name: "developers___zh_Hans",
    path: "/zh_Hans/developers",
    meta: developers0AZyZ7wzWyMeta || {},
    component: () => import("/app/src/pages/developers.vue").then(m => m.default || m)
  },
  {
    name: "developers___zh_Hant",
    path: "/zh_Hant/developers",
    meta: developers0AZyZ7wzWyMeta || {},
    component: () => import("/app/src/pages/developers.vue").then(m => m.default || m)
  },
  {
    name: "events-hk2024-banner___en___default",
    path: "/events/hk2024-banner",
    component: () => import("/app/src/pages/events/hk2024-banner.vue").then(m => m.default || m)
  },
  {
    name: "events-hk2024-banner___en",
    path: "/en/events/hk2024-banner",
    component: () => import("/app/src/pages/events/hk2024-banner.vue").then(m => m.default || m)
  },
  {
    name: "events-hk2024-banner___de",
    path: "/de/events/hk2024-banner",
    component: () => import("/app/src/pages/events/hk2024-banner.vue").then(m => m.default || m)
  },
  {
    name: "events-hk2024-banner___es_MX",
    path: "/es_MX/events/hk2024-banner",
    component: () => import("/app/src/pages/events/hk2024-banner.vue").then(m => m.default || m)
  },
  {
    name: "events-hk2024-banner___pt_BR",
    path: "/pt_BR/events/hk2024-banner",
    component: () => import("/app/src/pages/events/hk2024-banner.vue").then(m => m.default || m)
  },
  {
    name: "events-hk2024-banner___zh_Hans",
    path: "/zh_Hans/events/hk2024-banner",
    component: () => import("/app/src/pages/events/hk2024-banner.vue").then(m => m.default || m)
  },
  {
    name: "events-hk2024-banner___zh_Hant",
    path: "/zh_Hant/events/hk2024-banner",
    component: () => import("/app/src/pages/events/hk2024-banner.vue").then(m => m.default || m)
  },
  {
    name: "events-hk2024___en___default",
    path: "/events/hk2024",
    meta: hk2024qZH23YObV2Meta || {},
    component: () => import("/app/src/pages/events/hk2024.vue").then(m => m.default || m)
  },
  {
    name: "events-hk2024___en",
    path: "/en/events/hk2024",
    meta: hk2024qZH23YObV2Meta || {},
    component: () => import("/app/src/pages/events/hk2024.vue").then(m => m.default || m)
  },
  {
    name: "events-hk2024___de",
    path: "/de/events/hk2024",
    meta: hk2024qZH23YObV2Meta || {},
    component: () => import("/app/src/pages/events/hk2024.vue").then(m => m.default || m)
  },
  {
    name: "events-hk2024___es_MX",
    path: "/es_MX/events/hk2024",
    meta: hk2024qZH23YObV2Meta || {},
    component: () => import("/app/src/pages/events/hk2024.vue").then(m => m.default || m)
  },
  {
    name: "events-hk2024___pt_BR",
    path: "/pt_BR/events/hk2024",
    meta: hk2024qZH23YObV2Meta || {},
    component: () => import("/app/src/pages/events/hk2024.vue").then(m => m.default || m)
  },
  {
    name: "events-hk2024___zh_Hans",
    path: "/zh_Hans/events/hk2024",
    meta: hk2024qZH23YObV2Meta || {},
    component: () => import("/app/src/pages/events/hk2024.vue").then(m => m.default || m)
  },
  {
    name: "events-hk2024___zh_Hant",
    path: "/zh_Hant/events/hk2024",
    meta: hk2024qZH23YObV2Meta || {},
    component: () => import("/app/src/pages/events/hk2024.vue").then(m => m.default || m)
  },
  {
    name: "events-token2049-banner___en___default",
    path: "/events/token2049-banner",
    component: () => import("/app/src/pages/events/token2049-banner.vue").then(m => m.default || m)
  },
  {
    name: "events-token2049-banner___en",
    path: "/en/events/token2049-banner",
    component: () => import("/app/src/pages/events/token2049-banner.vue").then(m => m.default || m)
  },
  {
    name: "events-token2049-banner___de",
    path: "/de/events/token2049-banner",
    component: () => import("/app/src/pages/events/token2049-banner.vue").then(m => m.default || m)
  },
  {
    name: "events-token2049-banner___es_MX",
    path: "/es_MX/events/token2049-banner",
    component: () => import("/app/src/pages/events/token2049-banner.vue").then(m => m.default || m)
  },
  {
    name: "events-token2049-banner___pt_BR",
    path: "/pt_BR/events/token2049-banner",
    component: () => import("/app/src/pages/events/token2049-banner.vue").then(m => m.default || m)
  },
  {
    name: "events-token2049-banner___zh_Hans",
    path: "/zh_Hans/events/token2049-banner",
    component: () => import("/app/src/pages/events/token2049-banner.vue").then(m => m.default || m)
  },
  {
    name: "events-token2049-banner___zh_Hant",
    path: "/zh_Hant/events/token2049-banner",
    component: () => import("/app/src/pages/events/token2049-banner.vue").then(m => m.default || m)
  },
  {
    name: "events-token2049___en___default",
    path: "/events/token2049",
    meta: token2049kRfiaMobqVMeta || {},
    component: () => import("/app/src/pages/events/token2049.vue").then(m => m.default || m)
  },
  {
    name: "events-token2049___en",
    path: "/en/events/token2049",
    meta: token2049kRfiaMobqVMeta || {},
    component: () => import("/app/src/pages/events/token2049.vue").then(m => m.default || m)
  },
  {
    name: "events-token2049___de",
    path: "/de/events/token2049",
    meta: token2049kRfiaMobqVMeta || {},
    component: () => import("/app/src/pages/events/token2049.vue").then(m => m.default || m)
  },
  {
    name: "events-token2049___es_MX",
    path: "/es_MX/events/token2049",
    meta: token2049kRfiaMobqVMeta || {},
    component: () => import("/app/src/pages/events/token2049.vue").then(m => m.default || m)
  },
  {
    name: "events-token2049___pt_BR",
    path: "/pt_BR/events/token2049",
    meta: token2049kRfiaMobqVMeta || {},
    component: () => import("/app/src/pages/events/token2049.vue").then(m => m.default || m)
  },
  {
    name: "events-token2049___zh_Hans",
    path: "/zh_Hans/events/token2049",
    meta: token2049kRfiaMobqVMeta || {},
    component: () => import("/app/src/pages/events/token2049.vue").then(m => m.default || m)
  },
  {
    name: "events-token2049___zh_Hant",
    path: "/zh_Hant/events/token2049",
    meta: token2049kRfiaMobqVMeta || {},
    component: () => import("/app/src/pages/events/token2049.vue").then(m => m.default || m)
  },
  {
    name: "index___en___default",
    path: "/",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es_MX",
    path: "/es_MX",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pt_BR",
    path: "/pt_BR",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___zh_Hans",
    path: "/zh_Hans",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___zh_Hant",
    path: "/zh_Hant",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "kyc___en___default",
    path: "/kyc",
    meta: kycysXA7IzKSKMeta || {},
    component: () => import("/app/src/pages/kyc.vue").then(m => m.default || m)
  },
  {
    name: "kyc___en",
    path: "/en/kyc",
    meta: kycysXA7IzKSKMeta || {},
    component: () => import("/app/src/pages/kyc.vue").then(m => m.default || m)
  },
  {
    name: "kyc___de",
    path: "/de/kyc",
    meta: kycysXA7IzKSKMeta || {},
    component: () => import("/app/src/pages/kyc.vue").then(m => m.default || m)
  },
  {
    name: "kyc___es_MX",
    path: "/es_MX/kyc",
    meta: kycysXA7IzKSKMeta || {},
    component: () => import("/app/src/pages/kyc.vue").then(m => m.default || m)
  },
  {
    name: "kyc___pt_BR",
    path: "/pt_BR/kyc",
    meta: kycysXA7IzKSKMeta || {},
    component: () => import("/app/src/pages/kyc.vue").then(m => m.default || m)
  },
  {
    name: "kyc___zh_Hans",
    path: "/zh_Hans/kyc",
    meta: kycysXA7IzKSKMeta || {},
    component: () => import("/app/src/pages/kyc.vue").then(m => m.default || m)
  },
  {
    name: "kyc___zh_Hant",
    path: "/zh_Hant/kyc",
    meta: kycysXA7IzKSKMeta || {},
    component: () => import("/app/src/pages/kyc.vue").then(m => m.default || m)
  },
  {
    name: "legend-otc___en___default",
    path: "/legend-otc",
    meta: legend_45otcavja6wauzeMeta || {},
    component: () => import("/app/src/pages/legend-otc.vue").then(m => m.default || m)
  },
  {
    name: "legend-otc___en",
    path: "/en/legend-otc",
    meta: legend_45otcavja6wauzeMeta || {},
    component: () => import("/app/src/pages/legend-otc.vue").then(m => m.default || m)
  },
  {
    name: "legend-otc___de",
    path: "/de/legend-otc",
    meta: legend_45otcavja6wauzeMeta || {},
    component: () => import("/app/src/pages/legend-otc.vue").then(m => m.default || m)
  },
  {
    name: "legend-otc___es_MX",
    path: "/es_MX/legend-otc",
    meta: legend_45otcavja6wauzeMeta || {},
    component: () => import("/app/src/pages/legend-otc.vue").then(m => m.default || m)
  },
  {
    name: "legend-otc___pt_BR",
    path: "/pt_BR/legend-otc",
    meta: legend_45otcavja6wauzeMeta || {},
    component: () => import("/app/src/pages/legend-otc.vue").then(m => m.default || m)
  },
  {
    name: "legend-otc___zh_Hans",
    path: "/zh_Hans/legend-otc",
    meta: legend_45otcavja6wauzeMeta || {},
    component: () => import("/app/src/pages/legend-otc.vue").then(m => m.default || m)
  },
  {
    name: "legend-otc___zh_Hant",
    path: "/zh_Hant/legend-otc",
    meta: legend_45otcavja6wauzeMeta || {},
    component: () => import("/app/src/pages/legend-otc.vue").then(m => m.default || m)
  },
  {
    name: "legend-pay___en___default",
    path: "/legend-pay",
    meta: legend_45payqtDI5tR7Z0Meta || {},
    component: () => import("/app/src/pages/legend-pay.vue").then(m => m.default || m)
  },
  {
    name: "legend-pay___en",
    path: "/en/legend-pay",
    meta: legend_45payqtDI5tR7Z0Meta || {},
    component: () => import("/app/src/pages/legend-pay.vue").then(m => m.default || m)
  },
  {
    name: "legend-pay___de",
    path: "/de/legend-pay",
    meta: legend_45payqtDI5tR7Z0Meta || {},
    component: () => import("/app/src/pages/legend-pay.vue").then(m => m.default || m)
  },
  {
    name: "legend-pay___es_MX",
    path: "/es_MX/legend-pay",
    meta: legend_45payqtDI5tR7Z0Meta || {},
    component: () => import("/app/src/pages/legend-pay.vue").then(m => m.default || m)
  },
  {
    name: "legend-pay___pt_BR",
    path: "/pt_BR/legend-pay",
    meta: legend_45payqtDI5tR7Z0Meta || {},
    component: () => import("/app/src/pages/legend-pay.vue").then(m => m.default || m)
  },
  {
    name: "legend-pay___zh_Hans",
    path: "/zh_Hans/legend-pay",
    meta: legend_45payqtDI5tR7Z0Meta || {},
    component: () => import("/app/src/pages/legend-pay.vue").then(m => m.default || m)
  },
  {
    name: "legend-pay___zh_Hant",
    path: "/zh_Hant/legend-pay",
    meta: legend_45payqtDI5tR7Z0Meta || {},
    component: () => import("/app/src/pages/legend-pay.vue").then(m => m.default || m)
  },
  {
    name: "licenses___en___default",
    path: "/licenses",
    component: () => import("/app/src/pages/licenses.vue").then(m => m.default || m)
  },
  {
    name: "licenses___en",
    path: "/en/licenses",
    component: () => import("/app/src/pages/licenses.vue").then(m => m.default || m)
  },
  {
    name: "licenses___de",
    path: "/de/licenses",
    component: () => import("/app/src/pages/licenses.vue").then(m => m.default || m)
  },
  {
    name: "licenses___es_MX",
    path: "/es_MX/licenses",
    component: () => import("/app/src/pages/licenses.vue").then(m => m.default || m)
  },
  {
    name: "licenses___pt_BR",
    path: "/pt_BR/licenses",
    component: () => import("/app/src/pages/licenses.vue").then(m => m.default || m)
  },
  {
    name: "licenses___zh_Hans",
    path: "/zh_Hans/licenses",
    component: () => import("/app/src/pages/licenses.vue").then(m => m.default || m)
  },
  {
    name: "licenses___zh_Hant",
    path: "/zh_Hant/licenses",
    component: () => import("/app/src/pages/licenses.vue").then(m => m.default || m)
  },
  {
    name: "media-kit___en___default",
    path: "/media-kit",
    component: () => import("/app/src/pages/media-kit.vue").then(m => m.default || m)
  },
  {
    name: "media-kit___en",
    path: "/en/media-kit",
    component: () => import("/app/src/pages/media-kit.vue").then(m => m.default || m)
  },
  {
    name: "media-kit___de",
    path: "/de/media-kit",
    component: () => import("/app/src/pages/media-kit.vue").then(m => m.default || m)
  },
  {
    name: "media-kit___es_MX",
    path: "/es_MX/media-kit",
    component: () => import("/app/src/pages/media-kit.vue").then(m => m.default || m)
  },
  {
    name: "media-kit___pt_BR",
    path: "/pt_BR/media-kit",
    component: () => import("/app/src/pages/media-kit.vue").then(m => m.default || m)
  },
  {
    name: "media-kit___zh_Hans",
    path: "/zh_Hans/media-kit",
    component: () => import("/app/src/pages/media-kit.vue").then(m => m.default || m)
  },
  {
    name: "media-kit___zh_Hant",
    path: "/zh_Hant/media-kit",
    component: () => import("/app/src/pages/media-kit.vue").then(m => m.default || m)
  },
  {
    name: "newsroom.unuse___en___default",
    path: "/newsroom.unuse",
    component: () => import("/app/src/pages/newsroom.unuse.vue").then(m => m.default || m)
  },
  {
    name: "newsroom.unuse___en",
    path: "/en/newsroom.unuse",
    component: () => import("/app/src/pages/newsroom.unuse.vue").then(m => m.default || m)
  },
  {
    name: "newsroom.unuse___de",
    path: "/de/newsroom.unuse",
    component: () => import("/app/src/pages/newsroom.unuse.vue").then(m => m.default || m)
  },
  {
    name: "newsroom.unuse___es_MX",
    path: "/es_MX/newsroom.unuse",
    component: () => import("/app/src/pages/newsroom.unuse.vue").then(m => m.default || m)
  },
  {
    name: "newsroom.unuse___pt_BR",
    path: "/pt_BR/newsroom.unuse",
    component: () => import("/app/src/pages/newsroom.unuse.vue").then(m => m.default || m)
  },
  {
    name: "newsroom.unuse___zh_Hans",
    path: "/zh_Hans/newsroom.unuse",
    component: () => import("/app/src/pages/newsroom.unuse.vue").then(m => m.default || m)
  },
  {
    name: "newsroom.unuse___zh_Hant",
    path: "/zh_Hant/newsroom.unuse",
    component: () => import("/app/src/pages/newsroom.unuse.vue").then(m => m.default || m)
  },
  {
    name: newsroom5XLeoaQJfkMeta?.name,
    path: "/newsroom",
    meta: newsroom5XLeoaQJfkMeta || {},
    component: () => import("/app/src/pages/newsroom.vue").then(m => m.default || m),
    children: [
  {
    name: "newsroom-slug___en___default",
    path: ":slug(.*)*",
    component: () => import("/app/src/pages/newsroom/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-slug.unuse___en___default",
    path: ":slug().unuse",
    component: () => import("/app/src/pages/newsroom/[slug].unuse.vue").then(m => m.default || m)
  },
  {
    name: "newsroom___en___default",
    path: "",
    component: () => import("/app/src/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-rss.unuse___en___default",
    path: "rss.unuse",
    component: () => import("/app/src/pages/newsroom/rss.unuse.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsroom5XLeoaQJfkMeta?.name,
    path: "/en/newsroom",
    meta: newsroom5XLeoaQJfkMeta || {},
    component: () => import("/app/src/pages/newsroom.vue").then(m => m.default || m),
    children: [
  {
    name: "newsroom-slug___en",
    path: ":slug(.*)*",
    component: () => import("/app/src/pages/newsroom/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-slug.unuse___en",
    path: ":slug().unuse",
    component: () => import("/app/src/pages/newsroom/[slug].unuse.vue").then(m => m.default || m)
  },
  {
    name: "newsroom___en",
    path: "",
    component: () => import("/app/src/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-rss.unuse___en",
    path: "rss.unuse",
    component: () => import("/app/src/pages/newsroom/rss.unuse.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsroom5XLeoaQJfkMeta?.name,
    path: "/de/newsroom",
    meta: newsroom5XLeoaQJfkMeta || {},
    component: () => import("/app/src/pages/newsroom.vue").then(m => m.default || m),
    children: [
  {
    name: "newsroom-slug___de",
    path: ":slug(.*)*",
    component: () => import("/app/src/pages/newsroom/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-slug.unuse___de",
    path: ":slug().unuse",
    component: () => import("/app/src/pages/newsroom/[slug].unuse.vue").then(m => m.default || m)
  },
  {
    name: "newsroom___de",
    path: "",
    component: () => import("/app/src/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-rss.unuse___de",
    path: "rss.unuse",
    component: () => import("/app/src/pages/newsroom/rss.unuse.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsroom5XLeoaQJfkMeta?.name,
    path: "/es_MX/newsroom",
    meta: newsroom5XLeoaQJfkMeta || {},
    component: () => import("/app/src/pages/newsroom.vue").then(m => m.default || m),
    children: [
  {
    name: "newsroom-slug___es_MX",
    path: ":slug(.*)*",
    component: () => import("/app/src/pages/newsroom/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-slug.unuse___es_MX",
    path: ":slug().unuse",
    component: () => import("/app/src/pages/newsroom/[slug].unuse.vue").then(m => m.default || m)
  },
  {
    name: "newsroom___es_MX",
    path: "",
    component: () => import("/app/src/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-rss.unuse___es_MX",
    path: "rss.unuse",
    component: () => import("/app/src/pages/newsroom/rss.unuse.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsroom5XLeoaQJfkMeta?.name,
    path: "/pt_BR/newsroom",
    meta: newsroom5XLeoaQJfkMeta || {},
    component: () => import("/app/src/pages/newsroom.vue").then(m => m.default || m),
    children: [
  {
    name: "newsroom-slug___pt_BR",
    path: ":slug(.*)*",
    component: () => import("/app/src/pages/newsroom/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-slug.unuse___pt_BR",
    path: ":slug().unuse",
    component: () => import("/app/src/pages/newsroom/[slug].unuse.vue").then(m => m.default || m)
  },
  {
    name: "newsroom___pt_BR",
    path: "",
    component: () => import("/app/src/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-rss.unuse___pt_BR",
    path: "rss.unuse",
    component: () => import("/app/src/pages/newsroom/rss.unuse.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsroom5XLeoaQJfkMeta?.name,
    path: "/zh_Hans/newsroom",
    meta: newsroom5XLeoaQJfkMeta || {},
    component: () => import("/app/src/pages/newsroom.vue").then(m => m.default || m),
    children: [
  {
    name: "newsroom-slug___zh_Hans",
    path: ":slug(.*)*",
    component: () => import("/app/src/pages/newsroom/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-slug.unuse___zh_Hans",
    path: ":slug().unuse",
    component: () => import("/app/src/pages/newsroom/[slug].unuse.vue").then(m => m.default || m)
  },
  {
    name: "newsroom___zh_Hans",
    path: "",
    component: () => import("/app/src/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-rss.unuse___zh_Hans",
    path: "rss.unuse",
    component: () => import("/app/src/pages/newsroom/rss.unuse.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsroom5XLeoaQJfkMeta?.name,
    path: "/zh_Hant/newsroom",
    meta: newsroom5XLeoaQJfkMeta || {},
    component: () => import("/app/src/pages/newsroom.vue").then(m => m.default || m),
    children: [
  {
    name: "newsroom-slug___zh_Hant",
    path: ":slug(.*)*",
    component: () => import("/app/src/pages/newsroom/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-slug.unuse___zh_Hant",
    path: ":slug().unuse",
    component: () => import("/app/src/pages/newsroom/[slug].unuse.vue").then(m => m.default || m)
  },
  {
    name: "newsroom___zh_Hant",
    path: "",
    component: () => import("/app/src/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-rss.unuse___zh_Hant",
    path: "rss.unuse",
    component: () => import("/app/src/pages/newsroom/rss.unuse.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "partner-faq___en___default",
    path: "/partner/faq",
    component: () => import("/app/src/pages/partner/faq.vue").then(m => m.default || m)
  },
  {
    name: "partner-faq___en",
    path: "/en/partner/faq",
    component: () => import("/app/src/pages/partner/faq.vue").then(m => m.default || m)
  },
  {
    name: "partner-faq___de",
    path: "/de/partner/faq",
    component: () => import("/app/src/pages/partner/faq.vue").then(m => m.default || m)
  },
  {
    name: "partner-faq___es_MX",
    path: "/es_MX/partner/faq",
    component: () => import("/app/src/pages/partner/faq.vue").then(m => m.default || m)
  },
  {
    name: "partner-faq___pt_BR",
    path: "/pt_BR/partner/faq",
    component: () => import("/app/src/pages/partner/faq.vue").then(m => m.default || m)
  },
  {
    name: "partner-faq___zh_Hans",
    path: "/zh_Hans/partner/faq",
    component: () => import("/app/src/pages/partner/faq.vue").then(m => m.default || m)
  },
  {
    name: "partner-faq___zh_Hant",
    path: "/zh_Hant/partner/faq",
    component: () => import("/app/src/pages/partner/faq.vue").then(m => m.default || m)
  },
  {
    name: "partner___en___default",
    path: "/partner",
    component: () => import("/app/src/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: "partner___en",
    path: "/en/partner",
    component: () => import("/app/src/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: "partner___de",
    path: "/de/partner",
    component: () => import("/app/src/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: "partner___es_MX",
    path: "/es_MX/partner",
    component: () => import("/app/src/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: "partner___pt_BR",
    path: "/pt_BR/partner",
    component: () => import("/app/src/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: "partner___zh_Hans",
    path: "/zh_Hans/partner",
    component: () => import("/app/src/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: "partner___zh_Hant",
    path: "/zh_Hant/partner",
    component: () => import("/app/src/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: "policy___en___default",
    path: "/policy",
    component: () => import("/app/src/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "policy___en",
    path: "/en/policy",
    component: () => import("/app/src/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "policy___de",
    path: "/de/policy",
    component: () => import("/app/src/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "policy___es_MX",
    path: "/es_MX/policy",
    component: () => import("/app/src/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "policy___pt_BR",
    path: "/pt_BR/policy",
    component: () => import("/app/src/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "policy___zh_Hans",
    path: "/zh_Hans/policy",
    component: () => import("/app/src/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "policy___zh_Hant",
    path: "/zh_Hant/policy",
    component: () => import("/app/src/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "preference-email-unsubscribe___en___default",
    path: "/preference/email/unsubscribe",
    component: () => import("/app/src/pages/preference/email/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: "preference-email-unsubscribe___en",
    path: "/en/preference/email/unsubscribe",
    component: () => import("/app/src/pages/preference/email/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: "preference-email-unsubscribe___de",
    path: "/de/preference/email/unsubscribe",
    component: () => import("/app/src/pages/preference/email/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: "preference-email-unsubscribe___es_MX",
    path: "/es_MX/preference/email/unsubscribe",
    component: () => import("/app/src/pages/preference/email/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: "preference-email-unsubscribe___pt_BR",
    path: "/pt_BR/preference/email/unsubscribe",
    component: () => import("/app/src/pages/preference/email/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: "preference-email-unsubscribe___zh_Hans",
    path: "/zh_Hans/preference/email/unsubscribe",
    component: () => import("/app/src/pages/preference/email/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: "preference-email-unsubscribe___zh_Hant",
    path: "/zh_Hant/preference/email/unsubscribe",
    component: () => import("/app/src/pages/preference/email/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: "terms___en___default",
    path: "/terms",
    component: () => import("/app/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "terms___en",
    path: "/en/terms",
    component: () => import("/app/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "terms___de",
    path: "/de/terms",
    component: () => import("/app/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "terms___es_MX",
    path: "/es_MX/terms",
    component: () => import("/app/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "terms___pt_BR",
    path: "/pt_BR/terms",
    component: () => import("/app/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "terms___zh_Hans",
    path: "/zh_Hans/terms",
    component: () => import("/app/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "terms___zh_Hant",
    path: "/zh_Hant/terms",
    component: () => import("/app/src/pages/terms.vue").then(m => m.default || m)
  }
]