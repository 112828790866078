import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import setup_45global from "/app/src/middleware/setup.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  setup_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  ip2location: () => import("/app/src/middleware/ip2location.ts"),
  sosovalue: () => import("/app/src/middleware/sosovalue.ts")
}